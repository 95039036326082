import React from 'react';
import ProductPageTemplate from '../../components/ProductPageTemplate';

import rundbogenschiebetuer_00 from '../../images/rundbogenschiebetuer_00_1600.webp'
import rundbogenschiebetuer_01 from '../../images/rundbogenschiebetuer_01_1600.webp'
import rundbogenschiebetuer_02 from '../../images/rundbogenschiebetuer_02_1600.webp'
import rundbogenschiebetuer_03 from '../../images/rundbogenschiebetuer_03_1600.webp'
import rundbogenschiebetuer_04 from '../../images/rundbogenschiebetuer_04_1600.webp'
import rundbogenschiebetuer_thumb_00 from '../../images/rundbogenschiebetuer_00_400.webp'
import rundbogenschiebetuer_thumb_01 from '../../images/rundbogenschiebetuer_01_400.webp'
import rundbogenschiebetuer_thumb_02 from '../../images/rundbogenschiebetuer_02_400.webp'
import rundbogenschiebetuer_thumb_03 from '../../images/rundbogenschiebetuer_03_400.webp'
import rundbogenschiebetuer_thumb_04 from '../../images/rundbogenschiebetuer_04_400.webp'

const context = {
  title: 'Rundbogen&#173;schiebetür',
  description: 'Die optisch hochwertige Fassadenlösung für besonders anspruchsvolle Eingangssituationen, besonders funktionell ',
  text: 'Rundbogenschiebetüren bestehen aus einem Korpus welcher durch zwei rundgebogene automatische Schiebetüren begangen wird. Sie kann beispielsweise in ein Rahmenelement (Festverglasung) oder in ein Mauerwerk eingefasst werden. Die Einbaumaße der Rundbogenschiebetür bewegen sich meist in einem lichten Durchgangsmaß von 800 mm bis 2500 mm. \n\n' +

  'Rundbogenschiebetüren sind besonders repräsentativ und eindrucksvoll. Sie werden daher gerne in öffentlichen Gebäuden, Hotels oder hochwertigen Bürokomplexen eingesetzt. Im Gegensatz zur einfachen automatischen Schiebetür wirkt die Rundbogenschiebetüre gleichzeitig zu ihrer barrierefreien Durchgangsfunktion wie ein Windfang. Sie ist für den Einsatz im Flucht- und Rettungsweg zugelassen und bietet durch verschiedene Widerstandsklassen (RC2/RC3) einen besonderen Einbruchsschutz. Im Gegensatz zur Karusselldrehtür bleibt die einfache Durchlässigkeit des Personenverkehrs erhalten. \n\n' +

  'Nicht von der Stange -  jede Rundbogenschiebetür ist eine individuell angepasste Lösung. Denn erst wenn das Produkt und die örtlichen Gegebenheiten technisch und optisch perfekt ineinandergreifen, kann eine langfristig zufriedenstellende Eingangssituation entstehen. Wir von Automatiktür24 beraten Sie gerne und stehen für sämtliche Fragen zur Verfügung –smart, effizient, online.',
  features: [
    //'autschr',
    'barrierefreiheit',
    //'brandschutz',
    //'breakOut',
    'design',
    //'din',
    //'energieeinsparung',
    //'modernisierung',
    'notStrom',
    'personenfrequenz',
    //'platzsparend',
    'transparenz',
    'einbruchhemmung',
    'zutrittskontrolle',
    'fluchtweg',
  ],
  legalRequirements: [
    'autschr',
    'din18650',
    //'dinEn170',
    //'eltvtr',
  ],
  carouselImages: [
    {
      original: rundbogenschiebetuer_00,
      thumbnail: rundbogenschiebetuer_thumb_00,
      originalAlt: 'Rundbogenschiebetür'
    },
    {
      original: rundbogenschiebetuer_01,
      thumbnail: rundbogenschiebetuer_thumb_01,
      originalAlt: 'Rundbogenschiebetür'
    },
    {
      original: rundbogenschiebetuer_02,
      thumbnail: rundbogenschiebetuer_thumb_02,
      originalAlt: 'Rundbogenschiebetür'
    },
    {
      original: rundbogenschiebetuer_03,
      thumbnail: rundbogenschiebetuer_thumb_03,
      originalAlt: 'Rundbogenschiebetür'
    },
    {
      original: rundbogenschiebetuer_04,
      thumbnail: rundbogenschiebetuer_thumb_04,
      originalAlt: 'Rundbogenschiebetür'
    },
  ],
  configurator: true,
}

const Page = () => {

  return(
    <ProductPageTemplate pageContext={context} />
  )
}

export default Page;
